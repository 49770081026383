import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="COVID-19 対策サイト" link="https://github.com/Code-for-OKINAWA/covid19" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  沖縄県版のCOVID-19対策サイトです。有志と共に作成したサイトの更新業務を行っております。  
    </ProjectCard>
    <ProjectCard title="COVID-19 サマリ解析機" link="https://github.com/t-ube/parser-covid19-oka-summary" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  沖縄県が報道機関向けに公表しているコロナウィルス感染者情報を自動解析するためのパーサー・プログラムです。  
    </ProjectCard>
    <ProjectCard title="COVID-19 概況" link="https://okinawa-covid19map.netlify.app/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  沖縄県のコロナウィルス感染者情報を独自のフォーマットで見やすくまとめたサイトです。
    </ProjectCard>
    <ProjectCard title="Coming soon" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
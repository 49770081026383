import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Coder.OKINAWA.jp`}</h1>
    <p>{`コーダー・オキナワでは、沖縄県に関連するプログラムやソースコードを公開しています。`}<br parentName="p"></br>{`
`}{`また、オープンソース・プロジェクトの一環として、COVID-19感染症対策サイトの更新業務を担っています。  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`コーダー・オキナワではデジタルデータを活かしたインフォグラフィックスを製作しています`}</p>
    </blockquote>
    <p>{`オープンソース・プロジェクトでは、Python を使った自動文書解析と、収集したデジタルデータを使用した動的なSVG（スケーラブル・ベクター・グラフィックス）の作成を全自動で行っています。`}<br parentName="p"></br>{`
`}{`デジタルデータの収集元は HTML ベースの WEB サイトのほか、CSV 形式や PDF 形式などの公文書ファイル、企業が公開している WEB API を対象としています。  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      